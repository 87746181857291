jQuery(function($) {
    //MATCH-HEIGHTS
	$('.match-nav').matchHeight({byRow: false});
    $('.match-partner').matchHeight({byRow: false});
    $('.promo-item').matchHeight({byRow: false});
    $('.sales-preview-title').matchHeight({byRow: false});
    $('.sales-preview-cat').matchHeight({byRow: false});
    $('.sales-preview-table').matchHeight({byRow: false});
    

    $('a.button-jump[href^="#"]').on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top
        }, 500);
    });
	

	var current = location.pathname;
    $('a').each(function(){
        var $this = $(this);
        if($this.attr('href').indexOf(current) !== -1){
            $this.addClass('active');
        }
    })
	
	$('.navbar-toggler').on('click', function() {
		if ($(window).width() < 480) {
			$('#nav-mobile').css('width',$('#header-graphic').innerWidth()-20);
		}
	});
 
    
});